import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Pipelines = () => (
  <Layout>
    <SEO title="Pipelines" />

    <div className="page-content">
      <div className="container">
        <h1 className="display-4 text-orange">Pipelines</h1>
        <p>
          Gray Hawk Land Solutions has played an important role to our clients
          by providing specialized field services to pipeline and mid-stream
          companies across the United States. Our principal’s and management
          teams have successfully negotiated the rights-of-way for major
          pipeline & mid-stream projects throughout the continental United
          States.{" "}
        </p>
        <p>
          The cumulative amount of our experience paired with our expansive
          industry knowledge is what empowers us to deliver projects on time and
          on budget. By implementing our hands-on management approach, this
          allows Gray Hawk Land Solutions to ensure each pipeline project’s
          unique requirements are satisfied and that our clients receive
          superior professional project support.
        </p>
        <p>
          Gray Hawk Land Solutions' managing partners have years of experience
          managing the acquisition of cross-country pipelines, pipeline
          gathering systems, metering stations, cathodic protection sites, valve
          sites, compressor stations, tank farms, telecommunication facilities,
          and pipeline removal projects.
        </p>
        <h2 className="my-4 text-center" style={{ textTransform: "none" }}>
          For pipeline industry projects, Gray Hawk Land Solutions offers the
          following professional services:
        </h2>
        <div className="row mt-5">
          <div className="col-lg-6 mb-4">
            <div className="bg-black border-orange rounded p-5 h-100">
              <h3>PRE-ACQUISITION</h3>
              <p>
                The pre-acquisition phase is the foundation to a successful
                project. Gray Hawk Land Solutions works closely with client
                selected survey groups, prepares limited title certificates, and
                paves a smooth way for the whole process to be successful. Since
                the pre-acquisition stage is normally the first contact with the
                landowner, Gray Hawk Land Solutions strategically places agents
                that historically work best with the landowners in the specific
                location of the project. Creating a positive relationship with
                all shareholders is the foundation to your project’s success. We
                take the time upfront to ensure this happens by providing
                quality training to all our acquisition agents.
              </p>
              <p>
                <strong>OUR SERVICES INCLUDE:</strong>
              </p>
              <ul>
                <li>Abstract/title search</li>
                <li>Due diligence</li>
                <li>Feasibility and routing studies</li>
                <li>Non-Environmental Permitting</li>
                <li>Survey permitting</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 mb-4">
            <div className="bg-black border-orange rounded p-5 h-100">
              <h3>ACQUISITION</h3>
              <p>
                For every acquisition project, Gray Hawk Land Solutions’ goal is
                to negotiate in good faith by being honest and upfront with
                shareholders and by communicating clearly in regard to the
                project’s scope, objective and how the project will impact each
                shareholder. All communications between our agents are
                documented on a daily basis inside of the geoAMPS platform and
                are available to our clients in real time for their review.{" "}
              </p>
              <p>
                <strong>OUR SERVICES INCLUDE:</strong>
              </p>
              <ul>
                <li>Amended easements</li>
                <li>Document preparation</li>
                <li>Easement delineation</li>
                <li>Negotiations and acquisition</li>
                <li>Options to purchase easements</li>
                <li>Supplemental easements</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 mb-4">
            <div className="bg-black border-orange rounded p-5 h-100">
              {" "}
              <h3>CONSTRUCTION</h3>
              <p>
                During the construction phase, Gray Hawk Land Solutions becomes
                the liaison between our client and the shareholders allowing for
                clear dialogue that often leads to preferred solutions instead
                of contentious litigation. Gray Hawk Land Solutions’ acquisition
                agents are trained to assess damages, resolve problems and work
                through shareholder’s concerns to help the construction crews
                meet their scheduled milestone dates.{" "}
              </p>
              <p>
                <strong>OUR SERVICES INCLUDE:</strong>
              </p>
              <ul>
                <li>Condemnation support</li>
                <li>Construction support</li>
                <li>Damage claim settlement</li>
                <li>Project management</li>
                <li>
                  Reporting & Uploading field pictures to the geoAMPS platform
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 mb-4">
            <div className="bg-black border-orange rounded p-5 h-100">
              {" "}
              <h3>PROJECT MANAGEMENT</h3>
              <p>
                Gray Hawk Land Solutions has a very experienced management team
                that is familiar with the right-of-way processes necessary to
                manage large, multi-state projects successfully. Our management
                team has a long & successful history of working within the
                constraints of our client’s budgets, burn rates, and project
                schedules.
              </p>
              <p>
                <strong>OUR SERVICES INCLUDE:</strong>
              </p>
              <ul>
                <li>Budgeting</li>
                <li>Forecasting</li>
                <li>Overseeing and supervising</li>
                <li>Projecting</li>
                <li>Reporting</li>
                <li>Staffing</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Pipelines
